import { LandingFooter } from '@rategravity/marketing-components';
import { WHITE_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import { Helmet } from 'react-helmet';
import { StartNowContent } from '../components/start-now';
import { colors } from '../modules/colors';
import { www } from '../modules/navigation';

export const StartNow = () => {
  return (
    <React.Fragment>
      <Helmet>
        <style>{`
          nav {
            background-color: ${colors.AQUA};
          }
        `}</style>
      </Helmet>
      <main style={{ backgroundColor: WHITE_100 }}>
        <StartNowContent />
      </main>
      <LandingFooter {...{ mainSite: true, www }} />
    </React.Fragment>
  );
};
export default StartNow;
