import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import { OwnUpTextStyle } from '@rategravity/own-up-component-library-legacy/components/typography/text/style';
import React from 'react';
import { LandingPageHeaderText, LandingPageSectionTitleText } from '../../../components/typography';
import HeroBullet from '../../../images/icons/hero-bullet.svg';
import { CallToAction } from '../components';

const AnkleWrapperStyle = createOwnUpStyle({
  margin: 'auto',
  maxWidth: '477px',
  padding: '10px 0 50px',
  variants: {
    mediumAndDown: {
      padding: '40px 0 50px'
    }
  }
});

const AnkleWrapper = createOwnUpComponent('section', AnkleWrapperStyle);

const BulletsWrapperStyle = createOwnUpStyle({
  ...OwnUpTextStyle,
  margin: '0 auto',
  maxWidth: '320px',
  fontSize: '20px',
  lineHeight: '25.5px',
  paddingInlineStart: '15px',
  '& > li': {
    display: 'flex',
    listStyle: 'none',
    margin: '10px 0',
    ':before': {
      content: "''",
      display: 'inline-block',
      margin: '0 15px',
      transform: 'translateY(3px)',
      background: `url(${HeroBullet}) no-repeat top center `,
      backgroundSize: 'contain',
      minWidth: '18px'
    }
  },
  variants: {
    mediumAndDown: {
      fontSize: '16px'
    }
  }
});

const BulletsWrapper = createOwnUpComponent('ul', BulletsWrapperStyle);

const Bullets = () => (
  <BulletsWrapper>
    {['Free to use', 'No spam, ever', 'No hard credit checks'].map((bullet, i) => (
      <li key={`bullet-${i}`}>{bullet}</li>
    ))}
  </BulletsWrapper>
);

export const Ankle = () => (
  <AnkleWrapper>
    <LandingPageSectionTitleText>GET STARTED</LandingPageSectionTitleText>
    <div style={{ margin: 'auto', display: 'flex' }}>
      <LandingPageHeaderText>
        Build your profile.
        <br />
        Find a better deal.
      </LandingPageHeaderText>
    </div>
    <Bullets />
    <CallToAction />
  </AnkleWrapper>
);
