import { SwooshDivider } from '@rategravity/marketing-components';
import { OwnUpGridWrapper } from '@rategravity/own-up-component-library';
import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { colors } from '../../modules/colors';
import { ResponsiveSquiggleDivider } from '../squiggle-divider';
import { Ankle } from './ankle';
import { AquaSection } from './components';
import { Header } from './header';
import { Hero } from './hero';
import { Reviews } from './reviews';
import { StartNowValueProps as ValueProps } from './value-props';

const SwooshDividerWrapperStyle = createOwnUpStyle({
  '>div': {
    transform: 'rotateY(180deg)'
  },
  marginBottom: '-1px'
});

const SwooshDividerWrapper = createOwnUpComponent('div', SwooshDividerWrapperStyle);

export const StartNowContent = () => (
  <React.Fragment>
    <AquaSection>
      <Header />
      <OwnUpGridWrapper>
        <Hero />
      </OwnUpGridWrapper>
    </AquaSection>
    <OwnUpGridWrapper>
      <ValueProps />
    </OwnUpGridWrapper>
    <ResponsiveSquiggleDivider maxWidth={'90%'} />
    <OwnUpGridWrapper>
      <Reviews />
    </OwnUpGridWrapper>
    <SwooshDividerWrapper>
      <SwooshDivider
        topColor={colors.WHITE}
        bottomColor={colors.AQUA}
        desktopMaxWidth={1280}
        mobileLateralGutter={0}
      />
    </SwooshDividerWrapper>
    <AquaSection>
      <OwnUpGridWrapper>
        <Ankle />
      </OwnUpGridWrapper>
    </AquaSection>
  </React.Fragment>
);
