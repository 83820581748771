import React from 'react';
import { startNowReviews } from '../../../data/content/start-now/reviews';
import { HeadlineTextWithStars } from '../../headline-text-with-stars';
import { GroupedReviews } from '../../reviews/grouped-reviews';
import { landingPageImages as images } from '../../static-images/old/landing-page';

export const startNowReviewImageStyle = {
  borderRadius: '50%',
  maxWidth: '250px',
  margin: '30px'
};

export const ReviewsHeadline = (
  <div style={{ display: 'flex', justifyContent: 'center', width: '80%', margin: '0 auto' }}>
    <HeadlineTextWithStars
      headline={'What people are saying'}
      subtext={'Thousands of reviews averaging 4.9/5 stars across Google, Facebook and Zillow'}
    />
  </div>
);

export const Reviews = () => {
  const reviewsData = startNowReviews.map(({ image, quote, attribution }) => ({
    Image: images[image],
    quote,
    attribution
  }));

  return <GroupedReviews headline={ReviewsHeadline} reviewsData={reviewsData} />;
};
