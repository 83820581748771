export const startNowReviews = [
  {
    image: 'two-adults-two-children.png' as const,
    quote: "I've probably referred seven or eight people at this point.",
    attribution: 'Karim S.'
  },
  {
    image: 'two-people-with-house.png' as const,
    quote: 'What really stood out to me was the transparency that we got.',
    attribution: 'Katherine D.'
  },
  {
    image: 'two-people-in-garden.png' as const,
    quote: 'The ability to just do a soft credit check was much more comfortable.',
    attribution: 'Erika H.'
  }
];
