import { colors } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import {
  LandingPageHeaderTextStyle,
  LandingPageMainTextStyle
} from '../../../components/typography';
import GreenCaratDownSVG from '../../../images/icons/green-carat-down.svg';
import { UnderlinedLink } from '../../links';
import { CallToAction } from '../components';

const HeroWrapperStyle = createOwnUpStyle({
  margin: 'auto',
  maxWidth: '477px',
  padding: '10px 0 20px',
  variants: {
    medium: {
      marginTop: '50px'
    },
    large: {
      marginTop: '50px'
    },
    mediumAndDown: {
      padding: '40px 0 40px'
    }
  }
});

const HeroWrapper = createOwnUpComponent('section', HeroWrapperStyle);

const HeaderTextStyle = createOwnUpStyle({
  ...LandingPageHeaderTextStyle,
  color: colors.LOGO_SHADOW,
  maxWidth: '10em',
  marginLeft: 'auto',
  marginRight: 'auto'
});

const HeaderText = createOwnUpComponent('div', HeaderTextStyle);

const MainTextStyle = createOwnUpStyle({
  ...LandingPageMainTextStyle,
  color: colors.PRIMARY,
  maxWidth: '21em',
  marginLeft: 'auto',
  marginRight: 'auto'
});

const MainText = createOwnUpComponent('div', MainTextStyle);

const GreenCaratDown = () => (
  <img
    src={GreenCaratDownSVG}
    alt="" // decorative
    style={{ display: 'flex', justifyContent: 'center', margin: '60px auto 0' }}
    height="9px"
  />
);

export const Hero = () => (
  <HeroWrapper>
    <HeaderText>A great mortgage rate is a few clicks away</HeaderText>
    <MainText>
      Rates vary widely. It pays to shop. Customers consistently save at least{' '}
      <UnderlinedLink
        href="/methodology"
        aria-label="over $27,000; click to see how we got this number"
      >
        $27,000+
      </UnderlinedLink>{' '}
      over the life of their loan.
    </MainText>
    <CallToAction />
    <GreenCaratDown />
  </HeroWrapper>
);
